import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Store/actions/apiActions";
import CustomDivider from "../../Components/Common/CustomDivider";
import { useAuth } from "react-oidc-context";

const Gifts = () => {
  const { currentInvitee } = useSelector((state) => state.apiReducer);
  const dispatch = useDispatch();
  
    const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated
      && auth?.user?.id_token
      // && auth?.user?.profile["custom:userId"]
    ) {
      dispatch(getCurrentUser(auth.user));
    }
  }, [dispatch, auth]);

  const MyCustomDivider = () => {
    return <CustomDivider style={{ margin: `15px 40%`, width: `20%` }} />
  }

  return (
    <Grid container>
      <Grid item xs={1} md={3} />
      <Grid item xs={10} md={6}>
        <Box
          style={{
            position: "relative",
            backgroundImage: `url('/WeddingLogoWatermark.svg')`, // Replace with the actual path to your watermark image
            backgroundPosition: "center", // Adjust as needed
            backgroundRepeat: "no-repeat", // Adjust as needed
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          }}
          sx={{
            width: "100%",
          }}
        >
          <br />
          <Typography variant="h3">
            Dearest {currentInvitee?.name},
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            We are greatful for any gifts we receive.
          </Typography>
          <Typography variant="h4">
            We are in the process of finalizing our gift registry and
          </Typography>
          <Typography variant="h4">
            will provide you with more information soon,
          </Typography>
          <Typography variant="h4">
            via this page.
          </Typography>
          <Typography variant="h4">
            Thank you for your patience.
          </Typography>
          <MyCustomDivider />
          <Typography variant="h4">
            You can also make use of the following bank details for monetary gifts.
          </Typography>
          <br/>
          <Box style={{ border: "2px #FF7F50 solid" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box style={{ borderRight: !md ? "2px #FF7F50 solid" : "none" }}>
                <Typography variant="h3">South Africa</Typography>
                  <Typography variant="h5">Name: Marco Roberto Benvenuti</Typography>
                  <Typography variant="h5">Bank: ABSA</Typography>
                  <Typography variant="h5">Account Number: 9389721243</Typography>
                  <Typography variant="h5">Branch code: 632005</Typography>
                  <Typography variant="h5">Account Type: Savings account</Typography>
                </Box>
              </Grid>
              {md && <MyCustomDivider/>}
              <Grid item xs={12} md={6}>
                <Box>
                <Typography variant="h3">Europe</Typography>
                  <Box fontSize="24px" >
                  <Typography variant="h5">IBAN: NL87 ABNA 0114 7632 83</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <br />
          <MyCustomDivider />
          <Typography variant="h4">
            The Bridal Party.
          </Typography>
          <Typography variant="h3">
            Gabriella & Marco
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={1} md={3} />
    </Grid>
  );
};

export default Gifts;
